// Here you can add other styles
.invalid-feedback {
    display: block;
}
.table .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; /* Adjust as needed */
}
